import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import classNames from 'classnames';

import { PlayArrow } from '../../../common/components/icons/play-arrow';
import { PlayIcon } from '../../../common/components/icons/play-icon';
import ImageLoader from '../../../common/components/image-loader';
import withDeviceType from '../../../common/hoc/with-device-type';
import withEventEmitter from '../../../common/hoc/with-event-emitter';
import withTranslate from '../../../common/hoc/with-translate';
import { buildUrl } from '../../../common/services/video-embed';
import getVideoEmbedThumbUrl from '../../../common/services/get-video-embed-thumb-url';
import styles from './video-embed.scss';
import withMediaHosts from '../../../common/hoc/with-media-hosts';

const ReactPlayer = React.lazy(() => import(/* webpackChunkName: "react-player" */ './react-player-wrapper'));

const MAX_WAIT_TIME = 5000;

const getStyle = (oembed) => ({ backgroundImage: `url(${getVideoEmbedThumbUrl(oembed)})` });

class VideoEmbed extends Component {
  state = {
    isLoading: false,
    isLoaded: false,
  };

  componentDidMount() {
    this.props.eventEmitter.on('video-start', this.handleVideoStart);
  }

  componentWillUnmount() {
    this.props.eventEmitter.removeListener('video-start', this.handleVideoStart);
    clearTimeout(this.timeout);
  }

  setPlayer = (player) => {
    this.player = player;
  };

  handleVideoStart = (player) => {
    if (this.player !== player) {
      this.setState({
        isLoading: false,
        isLoaded: false,
      });
    }
  };

  handlePlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isLoading: true });
    setTimeout(() => this.handleReady(), MAX_WAIT_TIME);
  };

  handleReady = () => {
    if (this.state.isLoading) {
      this.setState({ isLoaded: true });
    }
  };

  handleStart = () => {
    this.props.eventEmitter.emitEvent('video-start', [this.player]);
  };

  renderPlayIcon = () => (
    <span>
      <PlayIcon className={styles.icon} />
    </span>
  );

  renderThumbnail() {
    const { isLoading, isLoaded } = this.state;
    const { isMobile, isDesktop, t, oembed } = this.props;

    return (
      !isLoaded && (
        <div className={styles.thumbnail} style={getStyle(oembed)} onClick={this.handlePlay}>
          <div className={styles.overlay} />
          {!isLoading && isMobile && (
            <div className={styles.button}>
              <PlayArrow className={styles.arrow} /> {t('video-embed.load-video')}
            </div>
          )}
          {!isLoading && isDesktop && this.renderPlayIcon()}
          {isLoading && <ImageLoader type="medium" />}
        </div>
      )
    );
  }

  renderPlayer() {
    const { isLoading } = this.state;
    const { oembed, isDesktop, videoHost } = this.props;
    return (
      isLoading && (
        <Suspense fallback={null}>
          <ReactPlayer
            ref={this.setPlayer}
            className={styles.player}
            width="100%"
            height="100%"
            url={buildUrl(oembed.video_url, videoHost)}
            onReady={this.handleReady}
            onStart={this.handleStart}
            playing={isDesktop}
            controls
          />
        </Suspense>
      )
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={classNames(styles.videoEmbed, className)}>
        {this.renderPlayer()}
        {this.renderThumbnail()}
      </div>
    );
  }
}

VideoEmbed.propTypes = {
  oembed: PropTypes.object.isRequired,
  className: PropTypes.string,
  eventEmitter: PropTypes.object.isRequired, // HACK
  imageHost: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default flowRight(withTranslate, withDeviceType, withEventEmitter, withMediaHosts)(VideoEmbed);
