import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { resolveId } from '@wix/communities-blog-client-common';

import CreatePostButtonMobile from '../create-post-button-mobile';
import { isSeo as getIsSeo } from '../../../common/store/basic-params/basic-params-selectors';
import styles from './post-list.scss';

const renderItem = (props) => {
  /* eslint-disable react/prop-types */
  const { post, type, onLikeClick, ItemComponent, itemConfig, index } = props;

  const wrapperClass = classNames(styles.listItemMobile, 'post-list__post-list-item');
  const postId = resolveId(post);

  return (
    <div className={wrapperClass} key={postId} id={postId} data-id={postId}>
      <ItemComponent index={index} type={type} post={post} onLikeClick={onLikeClick} itemConfig={itemConfig} />
    </div>
  );
};

const PostListMobile = (props) => {
  const { isSeo, posts, showCreatePostAction } = props;
  const postsList = posts.map((post, index) => renderItem({ ...props, post, index }));

  if (isSeo) {
    return <div data-hook="post-list">{postsList}</div>;
  }

  return (
    <div data-hook="post-list">
      {showCreatePostAction && (
        <div key="create-post">
          <CreatePostButtonMobile />
        </div>
      )}
      {postsList}
    </div>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  type: PropTypes.string.isRequired,
  showCreatePostAction: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  itemConfig: PropTypes.object,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({ isSeo: getIsSeo(state) });

export default flowRight(connect(mapRuntimeToProps))(PostListMobile);
